
@import "../core/core.less";

body,
html {
	padding: 0;
	margin: 0;
	width: 100%;
	height: 100%;
	text-align: center;
}

body {		
    padding: 10px;
	background: @primary-color;
	font: 13px/20px normal Helvetica, Arial, sans-serif;
	color: @terceary-color;
}

#error {
    width: 100%;
    position: relative;
	top: 50%;
	transform: translateY(-50%);
}

h1 {
	margin:0;
	padding: 0;
	margin-bottom: .5rem;
	font-size: 2rem;
	line-height: 2.5rem;
	text-transform: uppercase;
	color: #fff;
}

img {
	max-width: 100%;
	margin-bottom: 3rem;
}

p {
	margin: 0;
	font-size: 1.3rem;
	line-height: 1.5rem;
	color: @terceary-color;
}

.message {
	margin: 0;
	padding: 0;
	margin-bottom: 2rem;
}

.corporate {
	color: @terceary-color;
}

a {
	display: inline-block;
	*display: inline;
	zoom: 1;
	padding: 8px 10px;
	background: orange;
	color: @terceary-color;
	font-weight: 600;
	text-decoration: none;

	&:hover {
		text-decoration: none;
		color: @terceary-color;
	}
}

ul {
	margin: 0;
	padding: 0;
	margin-bottom: 2rem;
}

li {
	list-style-type: none;
}